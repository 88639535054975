import React, { useState } from "react";
import { UIContext, UIContextType } from "./UIContext";

interface UIContextProviderProps {
  children: React.ReactNode | null;
}

interface UIContextProviderState {}

export const UIContextProvider = (props: UIContextProviderProps) => {
  const [
    state, setState
  ] = useState<UIContextProviderState>({});

  const updateState = (state: UIContextProviderState) => {
    setState(state)
  }

  const context: UIContextType = {
    ...state,
    updateState
  };

  return (
    <UIContext.Provider value={context}>{props.children}</UIContext.Provider>
  );
};
