import React from 'react';

const VisionSection = () => {
    return (
        <section className="section about" id="vision-values">
            <div className="container">
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12">
                        <div className="section-header section-header--animated section-header--tire section-header--small-margin text-left">
                            <h4>Education4Change</h4>
                            <h2><span style={{display: 'inline'}}>Vision and </span> Values</h2>
                        </div>
                        <div className="about__animated-content text-left">
                            <div className="row">
                            <p>
                                    E4C was born as a result of school leaders coming together with a shared passion for empowering pupils and staff to address racism head-on. The E4C programme aims to engage and open minds by challenging, inspiring and motivating children and young people to both prepare for, call out, and challenge racism when they experience or encounter it.
                                We want to see schools and organisations where diversity is celebrated, where misconceptions are challenged, and where equality is the expectation. We believe in equipping young people to be agents of positive change in their schools and communities, as well as in the world where they will have an impact as global citizens.
                                </p>
                                {/* <div className="col-md-6">
                                </div> */}
                                 <div className="col-md-3 pt-3" >
                                </div>
                                 <div className="col-md-6 pt-3" >
                                    <img src="img/9057-RT.jpg" data-jarallax-element="40" alt="" className="img-responsive rounded"></img>
                                </div>
                                <div className="col-md-3 pt-3" >
                                </div>
                                {/* <div className="col-md-6 pt-3">
                                    <img src="img/e4c-140.jpg" data-jarallax-element="10" alt="" className="img-responsive rounded"></img>
                                </div> */}
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img src="img/about-bg.png" data-jarallax-element="40" alt="" className="about__bg" /> */}
            <img src="img/docs-bg.png" data-jarallax-element="40" alt="" className="docs__bg"></img>
        </section>

    );
}

export default VisionSection;
