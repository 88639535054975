import React, { FormEvent, useEffect } from 'react'
import { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, Input } from 'reactstrap'
import * as API from '../../../../api/Api';


interface Props {}
export const ChangePassword = (props: RouteComponentProps<Props>) => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
   oldPassword: '',
   newPassword: '',
   newPasswordConfirm: ''
  });

  useEffect(() => {
    return () => {
      setLoading(false);

      setFormData(null as any);
    };
  }, []);

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if(!formData.oldPassword) {
      alert('Please enter old password');
      return;
    }
    if(!formData.newPassword) {
      alert('Please enter new password');
      return;
    }
    if(formData.newPassword !== formData.newPasswordConfirm) {
      alert('Confirm password does not match');
      return;
    }

    const data = formData;
    

    try {
      const res = await API.changePassword(data);
  
      if (res.result === true) {
        alert('Your password has been changed successfully');
      }
  
    } catch (error) {
      if (error.response && error.response.data) {
        alert(error.response.data.error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="row">
      <form onSubmit={(e) => onFormSubmit(e)} className="col-md-6">

        <h3 className="text-white mb-4">Change Password</h3>
        <div className="form-group">
          <Input
            type="password"
            className="form__input"
            placeholder="Current Password"
            required={true}
            maxLength={128}
            onChange={(e) => setFormData({...formData, oldPassword: e.target.value})}
          ></Input>
        </div>
        <div className="form-group">
          <Input
            type="password"
            className="form__input"
            placeholder="New Password"
            required={true}
            maxLength={128}
            minLength={6}
            onChange={(e) => setFormData({...formData, newPassword: e.target.value})}
          ></Input>
        </div>
        <div className="form-group">
          <Input
            type="password"
            className="form__input"
            placeholder="New Password (Confirm)"
            required={true}
            maxLength={128}
            minLength={6}
            onChange={(e) => setFormData({...formData, newPasswordConfirm: e.target.value})}
          ></Input>
        </div>
        
        
        <div className="btn-sign-in" style={{padding: 0, marginLeft: 0}}>
          <Button type="submit" className="btn-sign-up" style={{lineHeight: 'unset'}} disabled={loading}>
            { loading ? 'Please wait...' : 'Change password' }
          </Button>
        </div>
      </form>
    </div>
  )
}
