import React, { useState } from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, useParams } from "react-router";
import Plyr from 'plyr';

import * as API from '../../../../api/Api';

interface Props {}
export const SingleDocument = (props: RouteComponentProps<Props>) => {

    let { cat, doc } = useParams() as any;
    const [ document, setDocument ] = useState(null as any);
    const [ loading, setLoading ] = useState(false);
    const documents = [
        {
            _id: 1,
            link: 'https://e4c-resources.s3.eu-west-2.amazonaws.com/TEST-PDF-1.pdf',
            fileName: 'TEST-PDF-1.pdf',
            format: 'pdf',
            tags: ['pdf', 'document', 'test']
        },
        {
            _id: 2,
            link: 'https://e4c-resources.s3.eu-west-2.amazonaws.com/TEST-PP-1.pptx',
            fileName: 'TEST-PP-1.pptx',
            format: 'pptx',
            tags: ['pptx', 'document', 'test']
        },
        {
            _id: 3,
            link: 'https://e4c-resources.s3.eu-west-2.amazonaws.com/TEST-WORD-1.docx',
            fileName: 'TEST-WORD-1.docx',
            format: 'docx',
            tags: ['docx', 'word' , 'document', 'test']
        }
    ]

    let player;

    useEffect(() => {
        if(!document) {
            setLoading(true);
            try {
                // setDocument(documents.filter(d => d._id.toString() === doc)[0]);
                API.getDocumentById(cat, doc).then(res => {
                    if(res.result && (Array.isArray(res.data) && res.data.length > 0 )){
                        setDocument(res.data[0]);
                    }
                    setLoading(false);
                });
                // console.log(doc, document, documents.filter(d => d._id === doc)[0]);
            } catch {
                
            } finally {
            }
        }
    }, [document, documents, doc, cat])

    useEffect(() => {
        initPlayer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [document]);
    
    const initPlayer = (): void => {
        console.log('Start init player...')
        if(isMedia()) {
            player = new Plyr('#player');
            console.log('Init player...')
        }
    }

    const isMedia = () : boolean => {
        const mediaMimeTypes = ['audio/mpeg', 'audio/mp4', 'video/mp4', 'image/jpeg', 'image/jpg', 'image/png'];
        return document && mediaMimeTypes.indexOf(document.document.mimeType) !== -1;
    }

    const isAudio = (mimeType: string) => mimeType.startsWith('audio/')
    const isVideo = (mimeType: string) => mimeType.startsWith('video/')
    const isImage = (mimeType: string) => mimeType.startsWith('image/')
    
    return (
        loading ? <div>Please wait...</div> : document ? 
        <div>
            <h3 className="text-white mb-4">{document?.title}</h3>
            <p>{document.description}</p>
            {
                isMedia() ? 
                <div>
                    {isAudio(document?.document?.mimeType) && <audio id="player" controls><source src={document?.document?.url} type="audio/mp3" /></audio>}
                    {isVideo(document?.document?.mimeType) && <video id="player" controls><source src={document?.document?.url} type="video/mp4" style={{maxWidth: '720px', height: 'auto'}} /></video>}
                    {isImage(document?.document?.mimeType) && <img src={document?.document?.url} alt="Document" style={{width: 'auto', maxWidth: '100%', height: 'auto'}}  />}
                </div> :
                <iframe title="document viewer" src={'https://docs.google.com/viewer?embedded=true&url=' + encodeURI(document?.document?.url)} style={{width:'800px', height:'700px'}} frameBorder="0"></iframe>
            }
            <div>
                { document?.tags?.map((tag: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, i: React.Key | null | undefined) => 
                    <span key={i} className="badge badge-light mr-1">{tag}</span> 
                )}
            </div>
        </div> : 
        <div>Document was not found!</div>
    );
}