import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { RouteComponentProps, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext'
import { CategoryFilters } from '../../components/CategoryFilters/CategoryFilters'

import * as API from '../../../../api/Api';

interface Props {}
export const SingleCategory = (props: RouteComponentProps<Props>) => {
  const authCtx = useAuthContext()
  const history = useHistory();
  
  
  let { cat } = useParams() as any
  
  const pageSize = 12;
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('' as any);
  const [documents, setDocuments] = useState(null as any[] | null);
  const [hasMore, setHasMore] = useState(true);
  const [categoryId, setCategoryId] = useState('');

  useEffect(() => {
    setLoading(true);
    try {

      const _category = authCtx.categories?.find(c => c._id === cat);
      setCategory(_category)
      const _categoryId = _category?._id;
      setCategoryId(_categoryId);

      if(_category) {
        API.getDocuments(_categoryId, pageNumber, pageSize).then(res => {
          if(res.result && Array.isArray(res.data)) {
            setDocuments(res.data as any[]);
            setHasMore(res.data.length >= pageSize);
            setLoading(false);
          }
        });
      }

    } catch (e) {
      console.error(e);
      history.push('/dashboard')
    }
  }, [authCtx.categories, cat, history])

  const onFilterChange = (
    query: string,
    categoryId?: number,
    subcategoryId?: number
  ) => {
    setLoading(true);
    setHasMore(true);
    setPageNumber(1);

    const _categoryId = subcategoryId || (categoryId || category?._id);
    setCategoryId(_categoryId);

    API.getDocuments(_categoryId, pageNumber, pageSize, query).then(res => {
      if(res.result && Array.isArray(res.data)) {
        setDocuments(res.data as any[]);
        setHasMore(res.data.length >= pageSize);
      }
    }).finally(() => setLoading(false));
  }

  const loadMore = () => {
    const _pageNumber = pageNumber + 1;
    setPageNumber(_pageNumber);
    setLoading(true);
    API.getDocuments(categoryId, _pageNumber, pageSize).then(res => {
      if(res.result && Array.isArray(res.data)) {
        const docs = documents?.concat(res.data) ?? [];
        setDocuments(docs as any[]);
        setHasMore(res.data.length >= pageSize);
      }
    }).finally(() => setLoading(false));
  }

  const headers: any = () => {
    const tokenStr = localStorage.getItem('token')
    if (!tokenStr) {
      return null
    }
    return { Authorization: `${tokenStr}` }
  }

  const deleteDocument = (event: any, doc: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to delete this document?`) === true) {
      setLoading(true);
      API.apiRequest<any, API.GenericResponse<any>>(
        'delete',
        `api/upload/${doc._id}`,
        null,
        headers()
      ).then(() => {
        if(category) {
          API.getDocuments(category?._id, 1, pageSize).then(res => {
            if(res.result && Array.isArray(res.data)) {
              setDocuments(res.data as any[]);
              setHasMore(res.data.length >= pageSize);
            }
          }).finally(() => setLoading(false));
        }
      })
    }
  }

  return category ? (
    <div>
      <h3 className="text-white mb-4">{category?.name}</h3>
      <CategoryFilters
        categories={category.childs}
        onChange={(query, category, subcategory) =>
          onFilterChange(query, category, subcategory)
        }
      />
      {
        <div>
          {(!documents || documents?.length === 0) ? (!loading && <div>No documents found!</div>) :
          <div className="row">
            {documents?.map((doc) => (
              <div key={doc._id} className="col-lg-3 col-md-4 col-sm-6">
                <Link to={`/dashboard/category/${cat}/${doc._id}`} className="card mb-3">
                  <div className="card-body">
                    <h6 className="card-subtitle mb-2 text-muted">
                      {doc.document?.fileName}
                    </h6>
                    {doc?.tags?.map((tag: any) => (
                      <span key={tag} className="badge badge-dark mr-1">
                        {tag}
                      </span>
                    ))}
                  </div>
                </Link>
                {authCtx.isInRole('Admin') && (<a onClick={(e) => deleteDocument(e, doc)} style={{color: 'red', fontSize: 12, cursor: 'pointer'}}>Delete document</a>)}
              </div>
            ))}
          </div> }
          {!loading && hasMore && <div className='col-12 text-center mt-4'><button onClick={loadMore} className='btn btn--big btn--blue'>Load more</button></div>}
          {loading && <div className='col-12 text-center mt-4'>Please wait...</div>}
        </div>
      }
    </div>
  ) : (
    <div>Category was not found!</div>
  )
}
