import React, { FormEvent, useEffect } from 'react'
import { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { Button, Input, Label } from 'reactstrap'
import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext'
import { uploadFileService } from '../../../../lib/helpers/upload.service'
import * as styles from './UploadDocument.scss'
import * as API from '../../../../api/Api';


interface Props {}
export const UploadDocument = (props: RouteComponentProps<Props>) => {
  const authCtx = useAuthContext();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [catId, setCatId] = useState(null as any);
  const [subcatId, setSubcatId] = useState(null as any);
  const [subsubcatId, setSubsubcatId] = useState(null as any);

  const [subcategories, setSubcategories] = useState(null as any[] | null)
  const [subsubcategories, setSubsubcategories] = useState(null as any[] | null)

  const [selectedCats, setSelectedCats] = useState([] as string[]);

  const [formData, setFormData] = useState({
   title: null as any,
   description: null as any,
   tags: [] as string[],
   category: null as any,
   document: null as any 
  });

  useEffect(() => {
    return () => {
      setLoading(false);
      setLoadingUpload(false);

      setFormData(null as any);
      setSelectedCats([]);
    };
  }, []);

  const onFileInputChange = (e: any) => {
    console.log(e);
    
    const files = [].slice.call(e.dataTransfer ? e.dataTransfer.files : e.target.files);
    if(files?.length > 0) {
      setLoadingUpload(true);
      uploadFileService(files[0], (err, data) => {
        console.error(err);
        console.log(data);
        setFormData({...formData, document: data});
        setLoadingUpload(false);
      })
    }
  }

  const onCategoryChange = (catId: string) => {
    setCatId(catId);
    const selectedCat = authCtx.categories?.filter(c => c._id === catId)[0];
    if(selectedCat?.childs && selectedCat?.childs.length > 0) {
      setSubcategories(selectedCat.childs as any[]);
    } else {
      setSubcategories(null);
      setSubsubcategories(null);
    }
    setSubcatId(null);
    setSubsubcatId(null);
    setSelectedCats([]);
  }

  const onSubcategoryChange = (subcatId: string) => {
    setSubcatId(subcatId);
    if(!subcatId) {
      setSubcategories(null);
      return;
    }
    const selectedCat = subcategories?.filter(c => c._id === subcatId)[0];
    if(selectedCat?.childs && selectedCat?.childs.length > 0) {
      setSubsubcategories(selectedCat.childs as any[]);
    } else {
      setSubcategories(null);
    }
    setSubsubcatId(null);
  }

  const onCategoryChecked = (category: any, i: number) => {
    const _selectedCats = selectedCats;
    const index = _selectedCats.indexOf(category._id);
    const checked = index === -1;

    if(checked) {
      _selectedCats.push(category._id);
    } else {
      _selectedCats.splice(i, 1);
    }

    setSelectedCats(() => ([..._selectedCats]));
  }

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const category = subsubcatId || (subcatId || catId);
    if(!category) {
      alert('Please select document category');
      return;
    }

    if(!formData.document) {
      alert('Please select document');
      return;
    }

    let _selectedCats = [];
    if (selectedCats && selectedCats.length > 0) {
      _selectedCats = selectedCats;
    } else {
      _selectedCats.push(category);
    }

    const data = {...formData, category: _selectedCats };
    // setFormData({...formData, category });
    // console.log(data);

    try {
      const res = await API.submit(data);
      console.log(res);
  
      if (res.result === true) {
        history.push(`/dashboard/category/${_selectedCats[0]}/${res.data?._id}`);
      }
  
    } catch (err) {
      const error = err as unknown as any;
      if (error.response && error.response.data) {
        alert(error.response.data.error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="row">
      <form onSubmit={(e) => onFormSubmit(e)} className="col-md-6">

        <h3 className="text-white mb-4">Add new document</h3>
        <div className="form-group">
          <Input
            type="text"
            className="form__input"
            placeholder="Title"
            required={true}
            maxLength={128}
            onChange={(e) => setFormData({...formData, title: e.target.value})}
          ></Input>
        </div>
        <div className="form-group">
          <Input
            type="text"
            className="form__input"
            placeholder="Description"
            maxLength={256}
            onChange={(e) => setFormData({...formData, description: e.target.value})}
          ></Input>
        </div>
        <div className="form-group">
          <Input
            type="text"
            className="form__input"
            placeholder="Tags (separate with commas)"
            maxLength={250}
            onChange={(e) => setFormData({...formData, tags: e.target.value.split(',')})}
          ></Input>
        </div>
        <div className="form-group">
          <Input
            type="select"
            id="category"
            className="form__input"
            required={true}
            onChange={(e) => onCategoryChange(e.target.value)}
          >
            <option value={null as any}>Select category</option>
            {authCtx.categories?.map(c => 
              <option key={c._id} value={c._id}>{c.name}</option>
            )}
          </Input>
        </div>
        {
          subcategories ? 
            <div className="form-group">
              <Input
                type="select"
                id="subcategory"
                className="form__input"
                defaultValue=""
                onChange={(e) => onSubcategoryChange(e.target.value)}
              >
                <option value={null as any}>Select subcategory (optional)</option>
                {subcategories?.map(c => 
                  <option key={c._id} value={c._id}>{c.name}</option>
                )}
              </Input>
            </div>
          : ''
        }

        {
          subsubcategories ? 
            <div className="form-group checkbox-container">
              {/* <Input
                type="select"
                id="subsubcategory"
                className="form__input"
                defaultValue=""
                onChange={(e) => setSubsubcatId(e.target.value)}
              >
                <option value={null as any}>Select subcategory (optional)</option>
                {subsubcategories?.map(c => 
                  <option key={c._id} value={c._id}>{c.name}</option>
                )}
              </Input> */}

              <div key={selectedCats.length}>
              {subsubcategories?.map((c, i) => { 
                const checked = selectedCats.indexOf(c._id) !== -1;
                return (<Label key={c._id} style={{display: 'block'}}>
                  <Input
                    type="checkbox"
                    name="subsubcat"
                    className="form__input"
                    value={c._id}
                    checked={checked}
                    onChange={() => onCategoryChecked(c, i)}
                  />
                  {c.name}
                </Label>);
              })}
              </div>
            </div>
          : ''
        }

        <div className="form-group file-input" placeholder={formData.document ? formData.document?.fileName : 'Upload file'}>
          <Input
            type="file"
            id={ styles.file }
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, .mp3, .mp4, .jpg, .jpeg, .png"
            className="form__input"
            onChange={(e) => onFileInputChange(e)}
          ></Input>
        </div>
        
        <div className="btn-sign-in" style={{padding: 0, marginLeft: 0}}>
          <Button type="submit" className="btn-sign-up" style={{lineHeight: 'unset'}} disabled={loading || loadingUpload}>
            { loadingUpload ? 'Uploading document...' : (loading ? 'Please wait...' : 'Add document') }
          </Button>
        </div>
      </form>
    </div>
  )
}
