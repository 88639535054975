import React, { useEffect } from "react";
import { useState } from "react";
import { Input } from "reactstrap";
import { UseErrorHandler } from "../../../../lib/hooks/useErrorHandler";


interface Props {
    categories: any[] | null,
    onChange: (query: string, category?: number, subcategory?: number) => void,
    error?: string | UseErrorHandler;
    children?: React.ReactNode;
}

export const CategoryFilters = (props: Props) => {
    const [category, setCategory] = useState(null as any);
    const [subcategory, setSubcategory] = useState(null as any);
    const [searchQuery, setSearchQuery] = useState('');
    // if (!props.error) {
    //     return <>{props.children}</>;
    // }

    useEffect(() => {
        onChange(searchQuery, subcategory ? null : category, subcategory)
    }, [category, subcategory, searchQuery]);

    const hasCategory = () => {
        return props.categories && props.categories.length > 0;
    }

    const hasSubcategory = () => {
        if (!hasCategory && !category) return false;
        const subcats = props?.categories?.find(c => c._id === category)?.childs;
        return subcats && Array.isArray(subcats);
    }

    const catInput = () => {
        return  (!props.categories || (props.categories && props.categories.length === 0)) ? '' : <div className="col-md-3"><Input
          type="select"
          id="category"
          className="form__input"
          placeholder="Category"
          onChange={(e) => {setCategory(e.target.value)}}
        //   value={formik.values.package}
        //   invalid={!!formik.errors.package && formik.touched.package}
        >
            <option value="">Select category</option>
          {props.categories.map((c: any) => <option key={c._id} value={c._id}>{c?.name}</option>)}
        </Input></div>
    }

    const subcatInput = () => {
        const subcats = category ? props.categories?.filter(c => c._id === category)[0]?.childs : null;
        return  subcats && subcats?.length > 0 ? <div className="col-md-3"><Input
          type="select"
          id="subcategory"
          className="form__input"
          placeholder="Subcategory"
          onChange={(e) => setSubcategory(e.target.value)}
        //   value={formik.values.package}
        //   invalid={!!formik.errors.package && formik.touched.package}
        >
            <option value="">Select subcategory</option>
            {subcats?.map((c: any) => <option key={c._id} value={c._id}>{c?.name}</option>)}
        </Input></div> : ''
    }

    let timeout: NodeJS.Timeout;
    const onChange = (s?: string, c?: string, sc?: string) => {
        if(timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            props.onChange(
                s ?? searchQuery,
                hasCategory() ? (c ? category : null) : null,
                hasSubcategory() ? (sc ? subcategory : null) : null
            );
        }, 100);
    }

    // return <p>{props.error.toString()}</p>;
    return <div className="row">
        {catInput()}
        {subcatInput()}
        <div className="col-md-3"><Input
          type="text"
          id="search"
          className="form__input"
          placeholder="Search documents"
          onChange={(e) => {setSearchQuery(e.target.value)}}
        //   value={formik.values.package}
        //   invalid={!!formik.errors.package && formik.touched.package}
        ></Input></div>
    </div>
};
