import React, { FormEvent, useEffect } from 'react'
import { useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext'
import { uploadFileService } from '../../../../lib/helpers/upload.service'
import * as API from '../../../../api/Api';
import { useRegisterFormik } from "../../../../components/RegisterForm/lib/useRegisterFormik";

//Components
import {
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Button,
  Label,
  ListGroup
} from "reactstrap";

import * as styles from './ActiveUsers.scss'


interface Props {}
export const ActiveUsers = (props: RouteComponentProps<Props>) => {
  
  const [loading, setLoading] = useState(false);
  const [activeUsers, setActiveUsers] = useState(null as any[] | null);
  const authCtx = useAuthContext();
  const history = useHistory();
  
  useEffect(() => {
    setLoading(true);
    try {

        API.getActiveUsers().then(res => {
          if(res.result && Array.isArray(res.data)) {
            console.log(res.data)
            setActiveUsers(res.data as any[]);
            setLoading(false);
          }
          console.log(res)
        });
      // }

    } catch (e) {
      console.error(e);
      history.push('/dashboard')
    }
  }, [history])


  return (
    <div style={{
        display: 'block', width: 700, padding: 30
    }}>
        <h3 style={{
          color: 'white',
         paddingBottom: 30
        }}>
      ACTIVE USERS
      </h3>
        
        
        <div>
          {(!activeUsers || activeUsers?.length === 0) ? (!loading && <div>No active users found!</div>) :
          <div>
           
            {activeUsers?.map((user, index) => (
              <div className="row"
                style ={{
                  paddingBottom:10
                }}
              >
               {index+1}. {user.firstName}   {user.lastName}  - {user.email}  |  School name: {user.schoolName}  |  Package: {user.package}  |  Role: {user.role}
              </div>
                          
            ))}
          </div> 
          }
          {loading && <div className='col-12 text-center mt-4'>Please wait...</div>}
        </div>
        {/* </ListGroup> */}

    </div>
);

}
