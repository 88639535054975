import React from "react"
import { FormGroup, Input, Form, Button } from "reactstrap"
import { useLoginFormik } from "./lib/useLoginFormik"
// import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';

import * as API from "../../api/Api"
import "./LoginForm.scss"
// import { useHistory } from "react-router"


export const LoginForm = () => {
  // const authCtx = useAuthContext();

  // const history = useHistory();

  const formik = useLoginFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.login({
          email: values.email,
          password: values.password
        })

        
        console.log("res", res)

        if(res.result === true) {
          if(res.data.link) {
            window.location.href = res.data.link;
            return;
          }
          
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          window.location.reload();
        }

        formikHelpers.resetForm()
      } catch (error) {
        if (error.response && error.response.data) {
          alert(error.response.data.error.message);
        }
      }
    }
  })
  return (
    <div className="LoginForm">
      <Form className="form" onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Input
            type="email"
            name="email"
            className="form__input"
            id="exampleEmail"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="password"
            name="password"
            className="form__input"
            id="examplePassword"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormGroup>
        <Button type="submit" className="form__btn btn btn--uppercase btn--orange">
          Login
        </Button>
      </Form>
    </div>
  )
}
