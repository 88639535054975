import React, { useEffect } from "react";

import { UIContextProvider } from "./lib/context/UIContext/UIContextProvider";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { ConfirmationContextProvider } from "./lib/context/ConfirmationContext/ConfirmationContextProvider";

//Partials
import { Header } from "./partials/Header/Header";
import { Footer } from "./partials/Footer/Footer";
import { Routes } from "./routes/Routes";


//Styles
import "./App.scss";
import { useAuthContext } from "./lib/context/AuthContext/AuthContext";
declare const jQuery: any;

function App() {
  const authCtx = useAuthContext();
  authCtx.checkAuthentication();
  
  useEffect(() => {
		setTimeout(function() {
			jQuery('.preloader').fadeOut();
		}, 500);
  }, []);

  return (
    <div className="App">
      <div className="preloader"></div>
      <div className="wrapper">
          <AuthContextProvider>
            <UIContextProvider>
              <ConfirmationContextProvider>
                <Header />
                <Routes />
                <Footer />
              </ConfirmationContextProvider>
            </UIContextProvider>
          </AuthContextProvider>
      </div>
    </div>
  );
}

export default App;
