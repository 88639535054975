import React from 'react';
import { Link } from 'react-router-dom';

//styles
import './Footer.scss';

export const Footer = () => {
  return <footer className="footer" style={{position: 'relative', zIndex: 3}}>
    <div className="container">
        <div className="row">
            <div className="col-lg-4">
                <Link to="/" className="logo">
                    <img className="logo__img logo__img--big" src="img/logo-white.svg" alt="" />
                    {/* <div className="logo__title">E4C</div> */}
                </Link>
                <div className="copyright">© 2021, Education 4 Change </div>
            </div>
            <div className="col-lg-4">
                <div className="social-block">
                    <div className="social-block__title">
                        Stay connected:
                    </div>

                    <ul className="social-list">
                        <li className="social-list__item">
                            <a href="https://twitter.com/Education4_C" target="_blank" rel="noreferrer" className="social-list__link">
                                <i className="fontello-icon icon-twitter">&#xf309;</i>
                            </a>
                        </li>
                        <li className="social-list__item">
                            <a href="https://www.instagram.com/education4change_uk/" className="social-list__link">
                                {/* <i className="fontello-icon icon-instagram">&#xf30c;</i> */}
                                <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px" style={{marginTop: '7px'}}>    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"/></svg>
                            </a>
                        </li>
                        {/* <li className="social-list__item">
                            <a href="mailto:generalenquiries@education4change.org.uk" className="social-list__link">
                                <i className="fontello-icon icon-telegram">&#xf2c6;</i>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="col-lg-4">
                {/* <form action="#" className="form subscribe" id="subscribe-form">
                    <div className="form__title">Subscribe</div>
                    <div className="form__row">
                        <input type="email" name="subscribe_email" className="form__input" placeholder="Email" />
                        <button className="form__btn btn btn--uppercase btn--orange btn--small"><span>Send</span></button>
                    </div>
                </form> */}
            </div>
        </div>
    </div>
  </footer>;
};
