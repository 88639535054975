import { useFormik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

export interface ForgotPasswordFields {
  email: string;
}

interface UseForgotPasswordFormOptions {
  initialValues?: ForgotPasswordFields;
  onSubmit: (
    values: ForgotPasswordFields,
    formikHelpers: FormikHelpers<ForgotPasswordFields>
  ) => Promise<any>;
}

export const useForgotPasswordFormik = (options: UseForgotPasswordFormOptions) => {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email!')
      .required('Email is required')
  });

  return useFormik({
    initialValues: {
      email: ''
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ForgotPasswordFormik = ReturnType<typeof useForgotPasswordFormik>;
