import React, { useContext } from 'react';
import * as API from '../../../api/Api';

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: API.User;
  categories?: any[];
  error?: string | { [key: string]: string };
  checkAuthentication: () => void;
  logout: () => void;
  isInRole: (role: string) => boolean
}

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  checkAuthentication: async () => {},
  logout: () => {},
  isInRole: (role: string) => false
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
