import { apiRequest, GenericResponse } from './Api';

export interface User {
  _id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  schoolName: string;
  package: string;
}

export interface LoginRegisterResponse {
  token: string;
  user: User;
  link?: string;
}

export interface RegisterInput {
  firstName: string,
  lastName: string,
  email: string;
  password: string;
  package: string;
  type: string;
  schoolName: string;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface ForgotPasswordInput {
  email: string;
}

export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm?: string;
}
export interface UserDetailsResponse {
  user: User;
}

export const register = async (data: RegisterInput) =>
  apiRequest<RegisterInput, GenericResponse<LoginRegisterResponse>>(
    'post',
    'api/user/register',
    data
  );

  export const registerManually = async (data: RegisterInput) =>
  apiRequest<RegisterInput, GenericResponse<LoginRegisterResponse>>(
    'post',
    'api/user/register-manually',
    data
  );

export const login = async (data: LoginInput) =>
  apiRequest<LoginInput, GenericResponse<LoginRegisterResponse>>('post', 'api/user/login', data);

export const forgotPassword = async (data: ForgotPasswordInput) =>
  apiRequest<ForgotPasswordInput, GenericResponse<any>>('post', 'api/user/forgot-password', data);

export const changePassword = async (data: ChangePasswordInput) => {
  delete data.newPasswordConfirm;
  return apiRequest<ChangePasswordInput, GenericResponse<any>>('put', 'api/user/password', data);
}

export const getUserDetails = async () =>
  apiRequest<undefined, UserDetailsResponse>('get', 'users/me');

export const getUserCategories = async () =>
  apiRequest<undefined, GenericResponse<any[]>>('get', 'api/category');

