import React from 'react';
import { RouteComponentProps } from "react-router";
import { useAuthContext } from '../../../../lib/context/AuthContext/AuthContext';
import './Profile.scss'

interface Props {}
export const Profile = (props: RouteComponentProps<Props>) => {

    const authCtx = useAuthContext();
    
    return (
        <div>
            <h3 className="text-white mb-4">Profile</h3>
            <div className="card" style={{maxWidth: '720px'}}>
                <div className="card-body">
                    <div className="form-group">
                        <p className="label">First Name</p>
                        <h4 className="content">{authCtx.user?.firstName}</h4>
                    </div>
                    <div className="form-group">
                        <p className="label">Last Name</p>
                        <h4 className="content">{authCtx.user?.lastName}</h4>
                    </div>
                    <div className="form-group">
                        <p className="label">Email</p>
                        <h4 className="content">{authCtx.user?.email}</h4>
                    </div>
                    {/* <div className="form-group mb-0">
                        <p className="label">Current Package</p>
                        <h4 className="content mb-0">{authCtx.user?.package}</h4>
                    </div> */}
                </div>
            </div>
        </div>
    );
}