import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import './ForgotPassword.scss';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm/ForgotPasswordForm';

interface Props {}

export const ForgotPassword = (props: RouteComponentProps<Props>) => {

    // const { register, handleSubmit, formState: { errors } } = useForm();

    // const validateEmail = (email) => {
    //     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(email);
    // }

    // const onSubmit = data => {
    //     if(!validateEmail(data.email)) {
    //         alert('Please enter valid email address');
    //         return;
    //     }

    //     const httpClient = new HttpClient();
    //     httpClient.post('api/user/login', { email: data.email, password: data.password }).then(res => {
    //         console.log('Data: ', res);
    //     }).catch(error => {
    //         if (error.response && error.response.data) {
    //             alert(error.response.data.error.message);
    //         }
    //     });
    // };

    return (
        <section className="section">
            <div className="container" style={{zIndex: 2, position: 'relative'}}>
                <div className="row">
                    <div className="col text-left">
                        <div className="section-header section-header--center section-header--medium-margin">
                            <h4>Reset your password</h4>
                            <h2>Forgot Password</h2>
                        </div>
                        
                        <ForgotPasswordForm />

                        <p className="text-center" style={{marginTop: '3rem'}}><Link to="/login" style={{color: 'white'}}>Login</Link></p>
                    </div>
                </div>
            </div>
            <img src="img/subscribe-bg.png" className="contact-bg" alt="" />
        </section>
    );
}
