import * as API from '../../api/Api';

const config = {
  maxImgPixels: 2400,
  maxFileSize: 5000000,
  allowedFiles: ['image/jpeg', 'image/gif', 'image/png', 'image/bmp', 'image/svg+xml']
}

export const uploadFileService = async (file: File, cb: (error: any, data: any) => void) => {
  
  const reader = new FileReader();
  reader.onload = async (e: any) => {
    const fileEntity = {
      mimeType: file.type,
      fileContent: e.target.result.split(',')[1],
      fileName: file.name,
    };

    
    // If upload document is image, check if its width or height exceed 2400px
    if (config.allowedFiles.indexOf(fileEntity.mimeType) !== -1) {
      const isAllowed = await checkImageWidthAndHeight(file);
      if (!isAllowed) {
        cb('Image width and height should be smaller than 2400px', null);
        return;
      }
    } else {
      console.log(fileEntity);
    }

    
    try {
      const res = await API.upload(fileEntity)
  
      if (res.result === true) {
        cb(null, res.data);
      }
  
    } catch (error) {
      if (error.response && error.response.data) {
        cb(error.response.data.error.message, null);
      }
    }

  };
  reader.readAsDataURL(file);

}



export const deleteFileService = async (fileKey: string, cb: (error: any, data: any) => void) => {
  try {
    const res = await API.deleteFile(fileKey)
    if (res.result === true) {
      cb(null, res.data);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      cb(error.response.data.error.message, null);
    }
  }
}

const checkFileSize = (file: any, maxSize?: number) => {
  if(!file || !file.size) {
    return false;
  }

  const maxFileSize = maxSize || config.maxFileSize;
  if(file.size > maxFileSize) {
    return false;
  }

  return true;
}

const checkImageWidthAndHeight = async (file:any, maxWidth?: number, maxHeight?: number): Promise<boolean> => {
  const maxImgWidth = maxWidth || config.maxImgPixels;
  const maxImgHeight = maxHeight || config.maxImgPixels;
  return new Promise<boolean>((resolve, reject) => {
    try {
      const _URL = window.URL;
      let img;
      img = new Image();
      img.onload = function (e: any) {
        if(e.width > maxImgWidth || e.height > maxImgHeight) {
          resolve(false);
        }
        resolve(true);
      };
      img.src = _URL.createObjectURL(file);
    } catch(e) {
      reject(e);
    }
  })
}