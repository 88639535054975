import { apiRequest, GenericResponse } from './Api'

export const getDocuments = async (categoryId: string, page = 1, size = 10, search?: string) =>
  apiRequest<any, GenericResponse<any>>(
    'get',
    `api/category/${categoryId}/documents?page=${page}&size=${size}` + (search ? `&search=${encodeURI(search)}` : ''),
    null,
    headers()
  )

export const getDocumentById = async (categoryId: string, documentId: string) =>
  apiRequest<any, GenericResponse<any>>(
    'get',
    `api/category/${categoryId}/documents?id=${documentId}`,
    null,
    headers()
  )


  export const getActiveUsers = async () =>
  apiRequest<undefined, GenericResponse<any[]>>(
    'get', 
    `api/user/active/${true}`
    );  

const headers: any = () => {
  const tokenStr = localStorage.getItem('token')
  if (!tokenStr) {
    return null
  }
  return { Authorization: `${tokenStr}` }
}
