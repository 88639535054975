import React from 'react';

const AmbasadorsSection = () => {
    return (
        <section className="section map" id="ambassadors-network-meetings">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated section-header--center section-header--medium-margin">
                  <h4>E4C</h4>
                  <h2>Ambassadors and Network Meetings</h2>
                </div>
              </div>
            </div>
            <div className="about__animated-content text-left">
            <div className="row">
                <p>
                Schools that sign up to an E4C package may choose to appoint an E4C Ambassador. These individuals will be instrumental to ensuring that the delivery of the programme fulfils the ambitions of the E4C vision, both internally and publicly. E4C Ambassadors will act as the representative and point of contact for all matters relating to the E4C programme within their setting.
                </p>
                <p>
                Ambassadors will be responsible for accelerating the effective delivery of their school’s chosen E4C package, ensuring that staff are able to participate in our training and that they use the E4C learning programme to deepen their understanding of issues related to diversity, equality and racism. E4C Ambassadors will work at all levels across their educational setting to help overcome any real or perceived barriers to addressing racism.
                All E4C Ambassadors are invited to join a half-termly network and support meeting, chaired by a member of the E4C Executive Team. These meetings provide the space for Ambassadors to discuss progress in their respective settings, share feedback and exchange ideas for best practice.
                </p>
           
             </div>
            </div>
            

            <div className="row">
              <div className="col-md-6 pt-3">
                  <img src="img/4389-RT.jpg" data-jarallax-element="10" alt="" className="img-responsive rounded mt-4 mb-4"></img>
              </div>
              <div className="col-md-6 pt-3">
                  <img src="img/8932-RT.jpg" data-jarallax-element="5" alt="" className="img-responsive rounded mt-5"></img>
              </div>
            </div>


          
           
            
            {/* <img src="img/road_map.png" data-jarallax-element="-40" alt="" className="map__title-bg" /> */}
          </div>
        </section>

    );
}

export default AmbasadorsSection;

