//styles
import "./Header.scss";

import { Link, useHistory } from "react-router-dom";
import React from "react";
import { useState } from "react";
import MainMenu from './MainMenu';
import closeMenuIcon from './closeMenu.svg';
import LanguageSwitcher from './LanguageSwitcher';
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

declare const document: any;

export const Header = () => {
  const history = useHistory();
  const authCtx = useAuthContext();


  const [openMenu, setOpenMenu] = useState(false);
  history.listen(() => setOpenMenu(false));
  
  const goToSection = (section: any) => {
    setTimeout(() => {
      try {
        document.getElementById(section).scrollIntoView({behavior: 'smooth'});
      } catch(e) {}
    }, 150);
  }

  return (

    <div>
      <header className="header">
          <Link onClick={() => goToSection('promo')} to="/" className="logo">
              <div className="logo__img"></div>
              {/* <div className="logo__title">E4C</div> */}
          </Link>

          <MainMenu />

          <div className="header__right">
              <LanguageSwitcher />
              <div className="sign-in-wrap">
                  {
                      authCtx.isAuthenticated ?
                        <div className="sign-in-wrap">
                            <Link to="/dashboard" className="btn-sign-up">Dashboard</Link>
                        </div> :
                        <div className="sign-in-wrap">
                            <Link to="/login" className="btn-sign-in">Login</Link>
                            <Link to="/register" className="btn-sign-up">Register</Link>
                        </div>

                  }
              </div>
          </div>

          <div className="btn-menu" onClick={() => setOpenMenu(true)}>
              <div className="one"></div>
              <div className="two"></div>
              <div className="three"></div>
          </div>
      </header>

      <div className={openMenu ? "fixed-menu open" : "fixed-menu"}>
          <div className="fixed-menu__header">
              <Link to="/" className="logo">
                  <div className="logo__img"></div>
                  <div className="logo__title">E4C</div>
              </Link>

              <div className="btn-close" onClick={() => setOpenMenu(false)}>
                  <img src={closeMenuIcon} style={{width: '28px', height: '28px'}} alt="close-menu" />
              </div>
          </div>

          <div className="fixed-menu__content">

              <MainMenu mobile={true} />
              <LanguageSwitcher />

                {
                      authCtx.isAuthenticated ?
                        <div className="btn-wrap">
                            <Link to="/dashboard" className="btn-sign-up">Dashboard</Link>
                        </div> :
                        <div className="btn-wrap">
                            <Link to="/login" className="btn-sign-in">Login</Link>
                            <Link to="/register" className="btn-sign-up">Register</Link>
                        </div>

                }


          </div>
      </div>
  </div>
  );
};
