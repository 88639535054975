import React from 'react';

const LanguageSwitcher = () => {
    return (
        <div className="d-none">
            <select className="select">
                <option value="ru">ru</option>
                <option value="ua">ua</option>
                <option value="en">en</option>
            </select>
        </div>
    );
}

export default LanguageSwitcher;
