import React from "react";
import { useRegisterFormik } from "./lib/useRegisterFormik";
import * as API from "../../api/Api"

//Components
import {
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Button,
  Row
} from "reactstrap";

//Style
import "./RegisterForm.scss";

export interface RegisterFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  schoolName:string;
  package: string;
  type: string;
}

export const RegisterForm = () => {
  const formik = useRegisterFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.register({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          schoolName: values.schoolName,
          package: values.package ?? 'C',
          type: values.type
        })
  
        if(res.result === true) {
          if(res.data.link) {
            window.location.href = res.data.link;
            return;
          }

          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(res.data.user));
          window.location.reload();
        }

        formikHelpers.resetForm()
      } catch (err) {
        const error = err as unknown as any;
        if (error.response && error.response?.data) {
          alert(error.response.data.error.message);
        }
      }
    },
  });

  const types = [
    'Nursery - £250', 
    'Infant schools - £350',
    'Junior schools - £350', 
    'Primary schools - £450', 
    'Secondary schools - £550',
    'Universities - £550', 
    'All-Through schools - £750',
    'PRU’s - £300' 
  ];
  
  return (
    <div className="RegisterForm">
      <Form className="form" onSubmit={formik.handleSubmit}>
        <FormGroup className="form-group-custom">
          <Input
            type="text"
            id="firstName"
            className="form__input"
            placeholder="First Name"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            invalid={!!formik.errors.firstName && formik.touched.firstName}
          />
          <FormFeedback>{formik.errors.firstName}</FormFeedback>
        </FormGroup>

        <FormGroup className="form-group-custom">
          <Input
            type="text"
            id="lastName"
            className="form__input"
            placeholder="Last Name"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            invalid={!!formik.errors.lastName && formik.touched.lastName}
          />
          <FormFeedback>{formik.errors.lastName}</FormFeedback>
        </FormGroup>

        <FormGroup className="form-group-custom">
          <Input
            type="email"
            id="email"
            className="form__input"
            placeholder="Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            invalid={!!formik.errors.email && formik.touched.email}
          />
          <FormFeedback>{formik.errors.email}</FormFeedback>
        </FormGroup>

        <FormGroup className="form-group-custom">
          <Input
            type="password"
            id="password"
            className="form__input"
            placeholder="Password"
            onChange={formik.handleChange}
            value={formik.values.password}
            invalid={!!formik.errors.password && formik.touched.password}
          />
          <FormFeedback>{formik.errors.password}</FormFeedback>
        </FormGroup>


        <FormGroup className="form-group-custom">
          <Input
            type="text"
            id="schoolName"
            className="form__input"
            placeholder="School Name"
            onChange={formik.handleChange}
            value={formik.values.schoolName}
            invalid={!!formik.errors.schoolName && formik.touched.schoolName}
          />
          <FormFeedback>{formik.errors.schoolName}</FormFeedback>
        </FormGroup>


        <FormGroup className="form-group-custom">
          
          {/* <Input
            type="select"
            id="package"
            className="form__input"
            placeholder="Package"
            onChange={formik.handleChange}
            value={formik.values.package}
            invalid={!!formik.errors.package && formik.touched.package}
          >
            <option value="A">Package A</option>
            <option value="B">Package B</option>
            <option value="C" selected>School Subscription</option>
          </Input> */}

          <Input
            type="select"
            id="type"
            className="form__input"
            placeholder="Type"
            onChange={formik.handleChange}
            value={formik.values.type}
            invalid={!!formik.errors.package && formik.touched.package}
          >
            <option value="" disabled>Select type</option>
            {types.map((type, i) => (<option value={type} key={i}>{type}</option>))}
          </Input>
          <FormFeedback>{formik.errors.package}</FormFeedback>
        </FormGroup>

        <Row>
          <div  style={{margin: 'auto',textAlign: 'center', paddingBottom:'30px'}}>
          For schools outside of Newham, please contact <a href="mailto:generalenquiries@education4change.org.uk" style={{color: 'white'}}>generalenquiries@education4change.org.uk</a>
          </div>
        
        </Row>

        <Button type="submit" className="form__btn btn btn--uppercase btn--orange">
          Register
        </Button>
      </Form>
    </div>
  );
};
