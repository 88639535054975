
import headerBackgroundImage from '../../assets/img/header-image-file.jpeg';

import React from 'react';
import { Link } from 'react-router-dom';

declare const document: any;

const PromoSection = () => {
    const scrollDown = () => {
        try {
          document.getElementById('home').scrollIntoView({behavior: 'smooth'});
        } catch(e) {}
    }

    const goToSection = (section: string) => {
      console.log(section, window.location.href);
      setTimeout(() => {
        try {
          document.getElementById(section).scrollIntoView({behavior: 'smooth'});
        } catch(e) {}
      }, 150);
    }

    return (
        <section className="promo" id="promo">
            <img src={headerBackgroundImage} sizes="100vw" className="header-img" alt="header-img" />
            {/* <video className="header-video" src="https://www.verse.com/av/53c744f419abf129a0f307158a3b4bc4e7e2a806/1h6iwvao9vedycqf/MPD/3456/VP9-1080-3456-video.webm" controls={false} autoPlay muted></video> */}
            <video className="header-video" src="https://e4c-bucket.s3.eu-west-2.amazonaws.com/e4c-starter.mp4" controls={false} autoPlay muted></video>
            <div className="container">
                <div className="row">
                    <div className="col-12 promo__content" data-aos="fade-right">
                        <h1 className="text-left">Challenging racism <span>through conversation and curriculum</span></h1>
                        <p className="text-left">
                        E4C is an educational charity that provides a programme of high-quality training and curriculum provision, equipping educators with the knowledge and confidence to start difficult conversations about race and racism. 
                </p>


                        <div className="promo__btns-wrap">
                            <Link to="/register" className="btn btn--medium btn--orange"><span>Subscribe now</span></Link>
                            <Link onClick={() => goToSection('offer')} to="/" className="btn btn--big btn--blue">See plans</Link>
                        </div>

                        {/* <div className="payments">
                            <img src={'img/visa.png'} alt="" />
                            <img src={'img/mc.png'} alt="" />
                            <img src={'img/bitcoin.png'} alt="" />
                        </div> */}
                    </div>
                </div>
                {/* <img src={'img/promo-bg.png'} data-aos="fade-up" alt="" className="promo__img" /> */}

            </div>
            <div className="scroll-down" style={{ zIndex: 4 }} onClick={() => scrollDown()}>
                <img src={'img/scroll-down.png'} alt="" />
            </div>

            <div className="bg-layer-1"></div>
            <div className="bg-layer-2"></div>
        </section>
    );
}

export default PromoSection;

