import React from 'react';
// import { useForm } from 'react-hook-form';
import { RouteComponentProps } from 'react-router';
import { RegisterForm } from '../../components/RegisterForm/RegisterForm';

interface Props {}

export const Register = (props: RouteComponentProps<Props>) => {
    // const { register, handleSubmit, formState: { errors } } = useForm();

    // const validateEmail = (email) => {
    //     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(email);
    // }

    // const onSubmit = data => {
    //     if(data.password !== data.passwordRepeat) {
    //         alert('Password does not match');
    //         return;
    //     }

    //     if(!validateEmail(data.email)) {
    //         alert('Please enter valid email address');
    //         return;
    //     }

    //     delete data.passwordRepeat;
    //     const httpClient = new HttpClient();
    //     httpClient.post('api/user/register', data).then(res => {
    //         console.log('Data: ', res);
    //     }).catch(error => {
    //         if (error.response && error.response.data) {
    //             alert(error.response.data.error.message);
    //         }
    //     });
    // };
    return (
        <section className="section">
            <div className="container" style={{zIndex: 2, position: 'relative'}}>
                <div className="row">
                    <div className="col text-left">
                        <div className="section-header section-header--center section-header--medium-margin">
                            <h4>Create new account</h4>
                            <h2>Register</h2>
                        </div>
                        {/* <form className="form" id="register-form" onSubmit={handleSubmit(onSubmit)}>
                            <input type="text" name="firstName" className="form__input" placeholder="First name" {...register("firstName", { required: true })} />
                            {errors.firstNameRequired && <span>This field is required</span>}

                            <input type="text" name="lastName" className="form__input" placeholder="Last name" {...register("lastName", { required: true })} />
                            {errors.lastNameRequired && <span>This field is required</span>}
                            <input type="email" name="email" className="form__input" placeholder="Email" {...register("email", { required: true })} />
                            <input type="password" name="password" className="form__input" placeholder="Password" {...register("password", { required: true })} />
                            <input type="password" name="password-repeat" className="form__input" placeholder="Password (repeat)" {...register("passwordRepeat", { required: true })} />
                            <select name="package" className="form__input" {...register("package", { required: true })}>
                                <option className="text-body" value="A">Package A</option>
                                <option value="B">Package B</option>
                                <option value="C">Package C</option>
                            </select>
                            <button type="submit" className="form__btn btn btn--uppercase btn--orange"><span>Register</span></button>
                        </form> */}

                        <RegisterForm />
                    </div>
                </div>
            </div>
            <img src="img/subscribe-bg.png" className="contact-bg" alt="" />
        </section>

        
    );
}
