import React from "react"
import { FormGroup, Input, Form, Button } from "reactstrap"
import { useForgotPasswordFormik } from "./lib/useForgotPasswordFormFormik"
// import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';

import * as API from "../../api/Api"
import "./ForgotPasswordForm.scss"
// import { useHistory } from "react-router"


export const ForgotPasswordForm = () => {
  // const authCtx = useAuthContext();

  // const history = useHistory();

  const formik = useForgotPasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.forgotPassword({
          email: values.email
        })

        
        console.log("res", res)

        if(res.result === true) {
          alert('Please check email in your inbox for further instructions')
        }

        formikHelpers.resetForm()
      } catch (error) {
        if (error.response && error.response.data) {
          alert(error.response.data.error.message);
        }
      }
    }
  })
  return (
    <div className="ForgotPasswordForm">
      <Form className="form" onSubmit={formik.handleSubmit}>
        <FormGroup>
          <Input
            type="email"
            name="email"
            className="form__input"
            id="exampleEmail"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormGroup>
        <Button type="submit" className="form__btn btn btn--uppercase btn--orange">
          Reset Password
        </Button>
      </Form>
    </div>
  )
}
