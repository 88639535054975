import React from 'react';
import ContactSection from './ContactSection';
import PromoSection from './PromoSection';
import TeamSection from './TeamSection';
import VisionSection from './VisionSection';
import OfferSection from './OffersSection';
import AmbasadorsSection from './AmbasadorsSection';
import PartnersSection from './PartnersSection';
import { RouteComponentProps } from 'react-router';

interface Props {}

export const Home = (props: RouteComponentProps<Props>) => {
    return (
        <div>
            <PromoSection />

            <section className="economy" id="about" style={{zIndex: 3}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-4">
                            
                            <a data-jarallax-element="-40" href="https://www.youtube.com/embed/B0lWoD6bG9c" className="economy__video-btn video-btn popup-youtube">
                              <img src="img/video-btn.png" alt="" />
                            </a>

                            <div className="economy__block">
                              <div className="economy__block-content">
                                <div className="section-header section-header--white section-header--tire section-header--small-margin">
                                  <h4 style={{fontSize: '18px'}}><span style={{textTransform: 'none'}}>About Education4Change (E4C)</span></h4>
                                  {/* <h2>
                                    A banking platform that <span>enables developer solutions</span>
                                  </h2> */}
                                </div>
                                <p className="text-left">
                                E4C is a programme designed for educators by educators to ensure schools provide high-quality, practical opportunities to explore diversity and racial issues in a meaningful way. All E4C packages offer a selection of curriculum resources and training provision, which empower educators to facilitate conversations with their pupils and colleagues about the existence and widespread impact of racism, ultimately raising expectations, shifting mindsets and enabling children and young people that encounter racism to overcome real and perceived barriers.
                                </p>
                                <p className="text-left">
                                E4C’s training modules cover a range of topics from unconscious bias and racial literacy, to significant British legislation relating to race and racism and its impact on the education system. This provision supports colleagues in educational settings to reflect on their policies and procedures and create a bespoke action plan that seeks to embed an ethos of anti-racism.
                                </p>
                                <p className="text-left">
                                Our Curriculum Resources Platform includes access to teaching guidance and conversation starters with a national and global perspective. We know conversation is a powerful tool, whether it is through small groups, whole staff teams or a one-to-one sharing of experiences, especially if facilitated by those who have a real understanding of the impact of racism on everyday life.
                                </p>
                              </div>
                            </div>

                            <img src="img/e4c-145.jpg" data-jarallax-element="40" alt="" className="img-responsive rounded" style={{position: 'absolute', top: '230px', left: '-60%', maxWidth: '60%', zIndex: 2}}></img>
                        </div>
                    </div>
                </div>
                <img src="img/video-bg.png" alt="" className="economy__bg" />
            </section>

        <VisionSection />
        <TeamSection />
        <OfferSection />
        <AmbasadorsSection />

        {/* <section className="partners-logo" id="partners-logo">
          <div className="container">
            <div className="row">
              <div data-aos="fade-up" className="col">
                <div className="partners-logo__block">
                  <div className="partners-logo__item">
                    <img src="img/partners-logo-1.png" alt="" />
                    <p>Escrow</p>
                  </div>
                  <div className="partners-logo__item">
                    <img src="img/partners-logo-2.png" alt="" />
                    <p>risk: low</p>
                  </div>
                  <div className="partners-logo__item">
                    <img src="img/partners-logo-3.png" alt="" />
                    <ul className="rating">
                      <li style={{backgroundImage: 'url(img/star-gold.svg)'}}></li>
                      <li style={{backgroundImage: 'url(img/star-gold.svg)'}}></li>
                      <li style={{backgroundImage: 'url(img/star-gold.svg)'}}></li>
                      <li style={{backgroundImage: 'url(img/star-gold.svg)'}}></li>
                      <li style={{backgroundImage: 'url(img/star.svg)'}}></li>
                    </ul>
                  </div>
                  <div className="partners-logo__item">
                    <img src="img/partners-logo-4.png" alt="" />
                    <p>risk: low</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <img src="img/partenrs-bg.png" data-jarallax-element="20" alt="" className="partners-logo__bg" />
        </section> */}

        {/* <section className="section cases">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated section-header--center section-header--medium-margin">
                  <h4>Some facts</h4>
                  <h2>Use Cases</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col cases__list">
                <div data-aos="fade-right" className="cases__item">
                  <img src="img/cases-icon-1.png" alt="" className="cases__item-icon" />
                  <div className="cases__item-content">
                    <div className="cases__item-title">
                      Cryptoland App
              </div>
                    <p className="cases__item-text">
                      Asiatic glassfish pilchard sandburrower, orangestriped triggerfish hamlet Molly Miller trunkfish spiny dogfish! Jewel tetra frigate mackerel
              </p>
                  </div>
                </div>
                <div data-aos="fade-left" data-aos-delay="200" className="cases__item">
                  <img src="img/cases-icon-2.png" alt="" className="cases__item-icon" />
                  <div className="cases__item-content">
                    <div className="cases__item-title">
                      Mining Service
              </div>
                    <p className="cases__item-text">
                      Spend real fights effective anything extra by leading. Mouthwatering leading how real formula also locked-in have can mountain thought. Jumbo
              </p>
                  </div>
                </div>
                <div data-aos="fade-right" className="cases__item">
                  <img src="img/cases-icon-3.png" alt="" className="cases__item-icon" />
                  <div className="cases__item-content">
                    <div className="cases__item-title">
                      Blockchain
              </div>
                    <p className="cases__item-text">
                      Clownfish catfish antenna codlet alfonsino squirrelfish deepwater flathead sea lamprey. Bombay duck sand goby snake mudhead
              </p>
                  </div>
                </div>
                <div data-aos="fade-left" data-aos-delay="200" className="cases__item">
                  <img src="img/cases-icon-4.png" alt="" className="cases__item-icon" />
                  <div className="cases__item-content">
                    <div className="cases__item-title">
                      Exchange
              </div>
                    <p className="cases__item-text">
                      Barbelless catfish pelican gulper candlefish thornfishGulf menhaden ribbonbearer riffle dace black dragonfish denticle herring
              </p>
                  </div>
                </div>
                <div data-aos="fade-right" className="cases__item">
                  <img src="img/cases-icon-5.png" alt="" className="cases__item-icon" />
                  <div className="cases__item-content">
                    <div className="cases__item-title">
                      Cryptoland
              </div>
                    <p className="cases__item-text">
                      Clownfish catfish antenna codlet alfonsino squirrelfish deepwater flathead sea lamprey. Bombay duck sand goby snake mudhead
              </p>
                  </div>
                </div>
                <div data-aos="fade-left" data-aos-delay="200" className="cases__item">
                  <img src="img/cases-icon-6.png" alt="" className="cases__item-icon" />
                  <div className="cases__item-content">
                    <div className="cases__item-title">
                      Cryptoland App
              </div>
                    <p className="cases__item-text">
                      Asiatic glassfish pilchard sandburrower, orangestriped triggerfish hamlet Molly Miller trunkfish spiny dogfish!
              </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <a href="" className="btn btn--orange btn--uppercase"><span>Join ICO</span></a>
            </div>
          </div>
          <img src="img/cases-bg.png" className="cases__bg" alt="" />
          <img src="img/cases-imgs.png" className="cases__elements" alt="" />
        </section> */}



{/* 
        <section className="docs" id="docs">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated seaction-header--center section-header--tire section-header--medium-margin">
                  <h4>Our files</h4>
                  <h2>Documents</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div data-aos="fade-up" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <a href="" className="doc">
                  <div className="doc__content">
                    <img src="img/pdf.svg" alt="" />
                    <div className="doc__title">
                      Terms & Conditions
              </div>
                  </div>
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <a href="" className="doc">
                  <div className="doc__content">
                    <img src="img/pdf.svg" alt="" />
                    <div className="doc__title">
                      White Pappers
              </div>
                  </div>
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="400" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <a href="" className="doc">
                  <div className="doc__content">
                    <img src="img/pdf.svg" alt="" />
                    <div className="doc__title">
                      Privacy Policy
              </div>
                  </div>
                </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="600" className="col-lg-3 col-md-6 col-sm-6 col-12">
                <a href="" className="doc">
                  <div className="doc__content">
                    <img src="img/pdf.svg" alt="" />
                    <div className="doc__title">
                      Business Profile
              </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <img src="img/docs-bg.png" data-jarallax-element="40" alt="" className="docs__bg" />
        </section> */}

        {/* <section className="data token-data section section--no-pad-bot">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated section-header--medium-margin section-header--center">
                  <h4>Our data</h4>
                  <h2>Token Distribution</h2>
                  <div className="bg-title">
                    Token Distribution
            </div>
                </div>
              </div>
            </div>
            <div className="row chart__row align-items-center">
              <div className="col-lg-6">
                <div className="chart">
                  <img className="chart__bg" src="img/chart-bg.png" alt="" />
                  <div className="chart__wrap">
                    <canvas id="myChart" width="400" height="400"></canvas>
                  </div>
                </div>
              </div>
              <div data-aos="fade-left" className="col-lg-6 token-data__animated-content">
                <div className="chart__title">
                  Allocation of funds
          </div>
                <p className="chart__text">
                  Total token supply  - 152,358
          </p>
                <ul className="chart__legend">
                  <li>
                    <span style={{width: '101px'}}></span>
              9% Founders and Team
            </li>
                  <li>
                    <span style={{width: '153px'}}></span>
              13% Reserved Funding
            </li>
                  <li>
                    <span style={{width: '34px'}}></span>
              2% Advisors
            </li>
                  <li>
                    <span style={{width: '289px'}}></span>
              25% Distribute to Community
            </li>
                  <li>
                    <span style={{width: '22px'}}></span>
              1% Bounty campaign
            </li>
                </ul>
              </div>
            </div>
          </div>
        </section> */}
{/* 
        <section className="section faq" id="faq">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--medium-margin">
                  <h4>FAQ</h4>
                  <h2>Frequency Asked Questions</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">

                <ul className="accordion">
                  <li>
                    <a>Can American citizens take part in the crowdsale?</a>
                    <p>
                      JavaScript is also used in environments that aren’t web-based, such as PDF documents, site-specific browsers, and desktop widgets. Newer and faster JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web applications. On the client side, JavaScript has been traditionally implemented as an interpreted language, but more recent browsers perform just-in-time compilation.
                </p>
                  </li>
                  <li>
                    <a>Does the crowdsale comply with legal regulations?</a>
                    <p>
                      JavaScript is also used in environments that aren’t web-based, such as PDF documents, site-specific browsers, and desktop widgets. Newer and faster JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web applications. On the client side, JavaScript has been traditionally implemented as an interpreted language, but more recent browsers perform just-in-time compilation.
                </p>
                  </li>
                  <li>
                    <a>Can I trade SCR at an exchange?</a>
                    <p>
                      JavaScript is also used in environments that aren’t web-based, such as PDF documents, site-specific browsers, and desktop widgets. Newer and faster JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web applications. On the client side, JavaScript has been traditionally implemented as an interpreted language, but more recent browsers perform just-in-time compilation.
                </p>
                  </li>
                  <li>
                    <a>What is the difference between Coin tokens and Power tokens?</a>
                    <p>
                      JavaScript is also used in environments that aren’t web-based, such as PDF documents, site-specific browsers, and desktop widgets. Newer and faster JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web applications. On the client side, JavaScript has been traditionally implemented as an interpreted language, but more recent browsers perform just-in-time compilation.
                </p>
                  </li>
                  <li>
                    <a>Why is Cryptonet economic model sustainable?</a>
                    <p>
                      JavaScript is also used in environments that aren’t web-based, such as PDF documents, site-specific browsers, and desktop widgets. Newer and faster JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web applications. On the client side, JavaScript has been traditionally implemented as an interpreted language, but more recent browsers perform just-in-time compilation.
                </p>
                  </li>
                  <li>
                    <a>Can I mine SCR?</a>
                    <p>
                      JavaScript is also used in environments that aren’t web-based, such as PDF documents, site-specific browsers, and desktop widgets. Newer and faster JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web applications. On the client side, JavaScript has been traditionally implemented as an interpreted language, but more recent browsers perform just-in-time compilation.
                </p>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="advisors">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated section-header--center section-header--big-margin">
                  <h4>Family</h4>
                  <h2>Advisors</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div data-aos="fade-right" className="col-md-6">
                <div className="advisor">
                  <a href="" className="advisor__img">
                    <img src="img/advisor-avatar-1.jpg" alt="" />
                    <div className="advisor__sn">
                      <img src="img/facebook.svg" alt="" />
                    </div>
                  </a>
                  <div className="advisor__content">
                    <div className="advisor__title">
                      David Drake
              </div>
                    <div className="advisor__post">
                      CEO Capital Limited
              </div>
                    <p className="advisor__text">
                      JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web
              </p>
                  </div>
                </div>
              </div>

              <div data-aos="fade-left" data-aos-delay="200" className="col-md-6">
                <div className="advisor">
                  <a href="" className="advisor__img">
                    <img src="img/advisor-avatar-2.jpg" alt="" />
                    <div className="advisor__sn">
                      <img src="img/linkedin.svg" alt="" />
                    </div>
                  </a>
                  <div className="advisor__content">
                    <div className="advisor__title">
                      Ann Balock
              </div>
                    <div className="advisor__post">
                      Cryptonet Speaker
              </div>
                    <p className="advisor__text">
                      JavaScript virtual machines (VMs) and platforms built upon them have also increased the popularity of JavaScript for server-side web
              </p>
                  </div>
                </div>
              </div>

              <div data-aos="fade-right" className="col-md-6">
                <div className="advisor">
                  <a href="" className="advisor__img">
                    <img src="img/advisor-avatar-3.jpg" alt="" />
                    <div className="advisor__sn">
                      <img src="img/google-plus.svg" alt="" />
                    </div>
                  </a>
                  <div className="advisor__content">
                    <div className="advisor__title">
                      Vladimir Nikitin
              </div>
                    <div className="advisor__post">
                      Cryptonet Team Lead
              </div>
                    <p className="advisor__text">
                      Giant wels roach spotted danio Black swallower cowfish bigscale flagblenny central mudminnow. Lighthousefish combtooth blenny
              </p>
                  </div>
                </div>
              </div>

              <div data-aos="fade-left" data-aos-delay="200" className="col-md-6">
                <div className="advisor">
                  <a href="" className="advisor__img">
                    <img src="img/advisor-avatar-4.jpg" alt="" />
                    <div className="advisor__sn">
                      <img src="img/facebook.svg" alt="" />
                    </div>
                  </a>
                  <div className="advisor__content">
                    <div className="advisor__title">
                      Sam Peters
              </div>
                    <div className="advisor__post">
                      Team Lead Advisor
              </div>
                    <p className="advisor__text">
                      Lampfish combfish, roundhead lemon sole armoured catfish saw shark northern stargazer smooth dogfish cod icefish scythe butterfish
              </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section> */}

        {/* <section className="section section--no-pad-top team" id="team">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--tire section-header--medium-margin">
                  <h4>Our brain</h4>
                  <h2>Awesome Team</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div data-aos="fade-right" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava1.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">David Drake</div>
                    <div className="team-member__post">UI Designer</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="100" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava2.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Allan Bellor</div>
                    <div className="team-member__post">Analitics</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="200" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava3.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Joe Doe</div>
                    <div className="team-member__post">Tech Operation</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="300" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava4.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Sam Tolder</div>
                    <div className="team-member__post">CEO</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava5.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Henry Polar</div>
                    <div className="team-member__post">SEO Specialist</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="100" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava6.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Sandra Pen</div>
                    <div className="team-member__post">Humar Resources</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="200" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava7.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Linda Gampton</div>
                    <div className="team-member__post">UX Team Lead</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="300" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava8.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">John Smith</div>
                    <div className="team-member__post">General Director</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava9.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Sam Oldrich</div>
                    <div className="team-member__post">Manager</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="100" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava10.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Denis Portlen</div>
                    <div className="team-member__post">Programmer</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="200" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava11.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Den Miller</div>
                    <div className="team-member__post">Economist</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div data-aos="fade-right" data-aos-delay="300" className="col-lg-4 col-xl-3 col-6">
                <div className="team-member">
                  <img className="team-member__avatar" src="img/ava12.png" alt="" />
                  <div className="team-member__content">
                    <div className="team-member__name">Brawn Lee</div>
                    <div className="team-member__post">Journalist</div>
                    <ul className="team-member__social">
                      <li><a href=""><img src="img/facebook.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/linkedin.svg" alt="" /></a></li>
                      <li><a href=""><img src="img/google-plus.svg" alt="" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src="img/team-bg.png" data-jarallax-element="40" alt="" className="team__bg" />
        </section> */}
{/* 
        <section className="news">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--small-margin">
                  <h4>In the world</h4>
                  <h2>Latest News</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">

                <div className="news-carousel owl-carousel">
                  <a data-aos="fade-up" href="" className="news-carousel__item">
                    <div className="news-carousel__item-body">
                      <div className="news-carousel__item-subtitle">Cryptocurrency</div>
                      <h3 className="news-carousel__item-title">
                        New trends in UI/UX Design World Integration
                </h3>
                      <p>
                        Specially for our VIP customers the LH Crypto team representatives Alexander Smirnov and Antonis Lapos will conduct a number of personal meet-
                </p>
                      <div className="news-carousel__item-data">
                        September, 15 2017
                </div>
                    </div>
                  </a>

                  <a data-aos="fade-up" data-aos-delay="200" href="" className="news-carousel__item">
                    <div className="news-carousel__item-body">
                      <div className="news-carousel__item-subtitle">Cryptocurrency</div>
                      <h3 className="news-carousel__item-title">
                        New trends in UI/UX Design World Integration
                </h3>
                      <p>
                        Specially for our VIP customers the LH Crypto team representatives Alexander Smirnov and Antonis Lapos will conduct a number of personal meet-
                </p>
                      <div className="news-carousel__item-data">
                        September, 15 2017
                </div>
                    </div>
                  </a>

                  <a data-aos="fade-up" data-aos-delay="200" href="" className="news-carousel__item">
                    <div className="news-carousel__item-body">
                      <div className="news-carousel__item-subtitle">Cryptocurrency</div>
                      <h3 className="news-carousel__item-title">
                        New trends in UI/UX Design World Integration
                </h3>
                      <p>
                        Specially for our VIP customers the LH Crypto team representatives Alexander Smirnov and Antonis Lapos will conduct a number of personal meet-
                </p>
                      <div className="news-carousel__item-data">
                        September, 15 2017
                </div>
                    </div>
                  </a>

                  <a href="" className="news-carousel__item">
                    <div className="news-carousel__item-body">
                      <div className="news-carousel__item-subtitle">Cryptocurrency</div>
                      <h3 className="news-carousel__item-title">
                        New trends in UI/UX Design World Integration
                </h3>
                      <p>
                        Specially for our VIP customers the LH Crypto team representatives Alexander Smirnov and Antonis Lapos will conduct a number of personal meet-
                </p>
                      <div className="news-carousel__item-data">
                        September, 15 2017
                </div>
                    </div>
                  </a>

                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="press section">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--center section-header--medium-margin">
                  <h4>Press About us</h4>
                  <h2>Press About Cryptoland</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-12 col-sm-6">
                <a href="" className="press__item">
                  <img src="img/press-logo-1.png" alt="" />
                </a>
              </div>
              <div className="col-lg-3 col-12 col-sm-6">
                <a href="" className="press__item">
                  <img src="img/press-logo-2.png" alt="" />
                </a>
              </div>
              <div className="col-lg-3 col-12 col-sm-6">
                <a href="" className="press__item">
                  <img src="img/press-logo-3.png" alt="" />
                </a>
              </div>
              <div className="col-lg-3 col-12 col-sm-6">
                <a href="" className="press__item">
                  <img src="img/press-logo-4.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </section> */}
        
        <PartnersSection />
        {/* <ContactSection /> */}
        </div>
    );
}
