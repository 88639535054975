import React from 'react';
import { RouteProps, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../routes/PrivateRoute';
import { PublicRoute } from '../../../routes/PublicRoute';
import { Page404 } from '../../Page404/Page404';
import { ChangePassword } from '../pages/ChangePassword/ChangePassword';
import { Profile } from '../pages/Profile/Profile';
import { SingleCategory } from '../pages/SingleCategory/SingleCategory';
import { SingleDocument } from '../pages/SingleDocument/SingleDocument';
import { UploadDocument } from '../pages/UploadDocument/UploadDocument';
import { RegisterUser } from '../pages/RegisterUser/RegisterUser';
import { ActiveUsers} from '../pages/ActiveUsers/ActiveUsers'


export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED,
}
interface AppRoute extends RouteProps {
  type?: RouteType;
}
export const AppRoutes: AppRoute[] = [
  // Restricted Routes
  // {
  //   type: RouteType.RESTRICTED,
  //   exact: true,
  //   path: 'login',
  //   component: Login,
  // },
  // {
  //   type: RouteType.RESTRICTED,
  //   exact: true,
  //   path: 'register',
  //   component: Register,
  // },
  // // {
  // //   type: RouteType.RESTRICTED,
  // //   exact: true,
  // //   path: 'forgot-password',
  // //   component: ForgotPassword,
  // // },
  // // {
  // //   type: RouteType.RESTRICTED,
  // //   exact: true,
  // //   path: 'reset-password',
  // //   component: ResetPassword,
  // // },
  // // Private Routes
  {
    type: RouteType.PRIVATE,
    path: 'dashboard/change-password',
    component: ChangePassword,
  },
  {
    type: RouteType.PRIVATE,
    path: 'dashboard/upload-document',
    component: UploadDocument,
  },
  {
    type: RouteType.PRIVATE,
    path: 'dashboard/register-user',
    component: RegisterUser,
  },
  {
    type: RouteType.PRIVATE,
    path: 'dashboard/active-users',
    component: ActiveUsers,
  },
  {
    type: RouteType.PRIVATE,
    path: 'dashboard/category/:cat/:doc',
    component: SingleDocument,
  },
  {
    type: RouteType.PRIVATE,
    path: 'dashboard/category/:cat',
    component: SingleCategory,
  },
  {
    type: RouteType.PRIVATE,
    path: 'dashboard',
    component: Profile,
  },
  // // Public Routes
  // {
  //   type: RouteType.PUBLIC,
  //   exact: true,
  //   path: '/',
  //   component: Home,
  // },
];

export const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r;
        return (
          <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
        )
      })}
      <PublicRoute component={Page404} />
    </Switch>
  );
};
