import Axios from 'axios';
import React, { useState, useEffect, SetStateAction } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

import * as API from '../../../api/Api';

import { AuthContextType, AuthContext } from './AuthContext';
import { User } from '../../../api/Api';
import { useHistory } from 'react-router';
import { getWithExpiry, setWithExpiry } from '../../helpers/localStorage.service';

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

const LOCAL_STORAGE_KEY = 'token';
const LOCAL_STORAGE_KEY_USER = 'user';

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const history = useHistory();

  const [authToken] = useLocalStorage(LOCAL_STORAGE_KEY);
  const [currentUser] = useLocalStorage(LOCAL_STORAGE_KEY_USER);


  const [user, setUser] = useState<API.User | undefined>(undefined);
  const [categories, setCategories] = useState<[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<
    string | { [key: string]: string } | undefined
  >(undefined);

  useEffect(() => {
    checkAuthentication();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    if (!authToken) {
      return;
    }

    try {
      if (!currentUser) {
        return;
      }
      const localUser = currentUser as unknown as User;
      setUser(localUser);

      
      Axios.defaults.headers.common.Authorization = `${authToken}`;
      try {
        let categories = getWithExpiry('categories');
        if(!categories) {
          const res = await API.getUserCategories();
          if(res.result === true) {
            categories =  res.data;
            const two_days = 2 * 24 * 60 * 60;
            setWithExpiry('categories', categories, two_days);
          }
        }
        setCategories(categories as SetStateAction<[] | undefined>);
      } catch {
        logout();
        return;
      }
    } catch (err) {
      setError((err as unknown as any).toString());
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    setUser(null as any);
    localStorage.clear();
    history.push('/login');
    window.location.reload();
  };
  

  const isInRole = (role: string) : boolean => {
    return user?.role === role;
  }

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    error: error,
    user: user,
    categories: categories,
    logout: logout,
    checkAuthentication,
    isInRole
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
