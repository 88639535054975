import React from 'react';

const TeamSection = () => {
    const otherTeam = [
        // {
        //     name: 'Lottie Howson',
        //     title: 'Project Communications Lead',
        //     // photo: 'img/team/ava2.png'
        // },
        {
            name: 'Clair Clements',
            title: 'Lead for School Communications, Events & Marketing and Learning Environment Consultant',
            photo: 'img/team/ava3.png'
        },
        // {
        //     name: 'Heather Godwin Da Silva',
        //     title: 'Social Media and Communications Lead',
        //     photo: 'img/team/ava4.png'
        // },
        {
            name: 'Lottie Howson',
            title: 'Lead for Partnership Communications, Research & Development',
            photo: 'img/team/ava2.png'
        },
    ]
    
    return (
        <section className="section about" id="meet-team">
            <style type="text/css">
                {`
                        @media (min-width: 1200px) {
                            .advisor__img:after {
                                right: -140%;
                                width: 140%;
                            }
                        }
                    `
                }
            </style>
            <div className="container">
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12">
                        <div className="section-header section-header--animated section-header--tire section-header--small-margin text-left">
                            <h4>Team</h4>
                            <h2><span style={{display: 'inline'}}>Meet the</span> E4C Team</h2>
                        </div>
                        <div className="about__animated-content">
                            <p>
                            E4C’s Executive Team is made up of two serving Newham Headteachers who share a determination to drive change in education. The aim of the E4C programme is to provide schools, leaders, teachers, governors and pupils with the training and resources to tackle racism in society, starting in the classroom. The Executive Team have the professional and personal expertise to provide this support so that you, as school leaders can make a difference.  </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* <div data-aos="fade-up" className="col-md-6">
                        <div className="advisor">
                            <div className="advisor__img">
                                <img src="img/team/Lorna-Jackson.jpg" alt="" />
                            </div>
                            <div className="advisor__content">
                                <div className="advisor__title">
                                    Lorna Jackson
                                </div>
                                <div className="advisor__post">
                                    Headteacher, Maryland Primary School
                                </div>
                                <p className="advisor__text">
                                    Lead for Curriculum and Research
                                </p>
                                <p className="advisor__text">
                                    <a href="img/team/Lornas-Bio.pdf" target="_blank" style={{color: 'white'}}>Bio</a>
                                </p>
                            </div>
                        </div>
                    </div> */}
                    <div data-aos="fade-up" data-aos-delay="200" className="col-md-6">
                        <div className="advisor">
                            <div className="advisor__img">
                                <img src="img/team/Lando-Du-Plooy.jpg" alt="" />
                                {/* <div className="advisor__sn">
                                    <img src="img/linkedin.svg" alt="" />
                                </div> */}
                            </div>
                            <div className="advisor__content">
                                <div className="advisor__title">
                                    Lando Du Plooy
                                </div>
                                <div className="advisor__post">
                                Lando DuPlooy, Headteacher, Dersingham Primary School
                                </div>
                                <p className="advisor__text">
                                Founder and Lead for Training and Development
                                </p>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="400" className="col-md-6">
                        <div className="advisor">
                            <div className="advisor__img">
                                <img src="img/team/Joan-Deslandes-OBE.jpg" alt="" />
                                {/* <div className="advisor__sn">
                                    <img src="img/google-plus.svg" alt="" />
                                </div> */}
                            </div>
                            <div className="advisor__content">
                                <div className="advisor__title">
                                    Joan Deslandes OBE
                                </div>
                                <div className="advisor__post">
                                    Headteacher, Kingsford Community School
                                </div>
                                <p className="advisor__text">
                                Founder and Lead for Curriculum, Accreditation and Partnerships
                                </p>
                            </div>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="200" className="col-md-6">
                        <div className="advisor">
                            <div className="advisor__img">
                                <img src="img/team/ava3.png" alt="" />
                                {/* <div className="advisor__sn">
                                    <img src="img/linkedin.svg" alt="" />
                                </div> */}
                            </div>
                            <div className="advisor__content">
                                <div className="advisor__title">
                                Clair Clements
                                </div>
                                <div className="advisor__text">
                                School Communications, Marketing & Learning Environment Consultant 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="400" className="col-md-6">
                        <div className="advisor">
                            <div className="advisor__img">
                                <img src="img/team/ava2.png" alt="" />
                                {/* <div className="advisor__sn">
                                    <img src="img/google-plus.svg" alt="" />
                                </div> */}
                            </div>
                            <div className="advisor__content">
                                <div className="advisor__title">
                                    Lottie Howson
                                </div>
                                <div className="advisor__text">
                                Partnership Communications, Research & Development Lead 
                                 </div>

                            </div>
                        </div>
                    </div>
                </div>
                

                {/* <div className="row">
                    {
                        otherTeam.map((member, i) => {
                            return (
                                <div data-aos="fade-up" data-aos-delay={i * 100} className="col-lg-6 col-xl-4 col-6" key={i}>
                                    <div className="team-member">
                                        {member.photo ? 
                                            <img className="team-member__avatar" src={member.photo} alt="" />
                                            : ''
                                        }
                                        <div className="team-member__content">
                                            <div className="team-member__name">{member.name}</div>
                                            <div className="team-member__post">{member.title}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>
            <img src="img/about-bg.png" data-jarallax-element="40" alt="" className="about__bg" />
        </section>

    );
}

export default TeamSection;
