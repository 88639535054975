import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const OfferSection = () => {

    const [offerPackage, setPackaage] = useState('A');
    
    return (
        <section className="section section--no-pad-bot services" id="offer">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated section-header--center section-header--medium-margin">
                  <h4>Package Offers Info</h4>
                  <h2>Newham Offer</h2>
                </div>

                <div className="services__items">
                  <div className="services__left" style={{width: '100%', justifyContent: 'center'}}>
                    <div data-aos="fade-up" className="service" style={{height: 60, width: 320}} onClick={() => setPackaage('A')}>
                      <div className="service__bg" style={{backgroundColor: '#e85f70', boxShadow: '0 0 51px rgba(232, 95, 112, 0.74)'}}></div>
                      {/* <img src="img/package/a.svg" style={{height: '63px', width: 'auto'}} alt="a" /> */}
                      <div className="service__title">
                        A 1-year subscription to the below
                      </div>
                    </div>
                    {/* <div data-aos="fade-up" data-aos-delay="200" className="service" onClick={() => setPackaage('B')}>
                      <div className="service__bg" style={{backgroundColor: '#fa8936', backgroundImage: 'linear-gradient(-234deg, #ea9d64 0%, #fa8936 100%)', boxShadow: '0 0 51px rgba(232, 95, 112, 0.74)'}}></div>
                      <img src="img/package/b.svg" style={{height: '63px', width: 'auto'}} alt="b" />
                      <div className="service__title">
                        PACKAGE B
                      </div>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="400" className="service" onClick={() => setPackaage('C')}>
                      <div className="service__bg" style={{ backgroundImage: 'linear-gradient(-234deg, #6ae472 0%, #4bc253 100%)', boxShadow: '0 0 51px rgba(75, 194, 83, 0.74)'}}></div>
                      <img src="img/package/c.svg" style={{height: '63px', width: 'auto'}} alt="c" />
                      <div className="service__title">
                        PACKAGE C
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 mt-5">
                  <div className="card" data-aos="fade-up" style={{backgroundColor: '#171b29'}}>
                    {
                      offerPackage === 'A' ?
                      <div className="card-body text-left">
                        <ul className="package-list ml-3 mb-3">
                          <li>Access to the E4C Curriculum Resources Platform </li>
                          <li>Access to E4C curriculum programmes including Create4Change</li>
                          <li>Access to the Strength4Change programme (email for more information)</li>
                          <li>Support for your E4C Ambassador via termly network meetings</li>
                          <li>Access to Ambassador networking app</li>
                          <li>Termly E4C Newsletter </li>
                          <li>Access to the British Film Institute resources with schemes of work and teacher guidance</li>
                          <li>Access to an E4C Lead Facilitator to help structure school discussions on the issue of racism, identifying strengths and areas for development as part of the school's self-evaluation</li>
                          <li>Reading lists for pupils and staff</li>
                          <li>One person attendance at E4C annual conference</li>
                        </ul>

                        <div style={{paddingBottom:'30px'}}>

                          Additional Costs
                          <ul className="package-list ml-3 mb-3">
                            <li>School CPD</li>
                            <li>School Environment Consultancy</li>
                          </ul>
                          Email: <a href="mailto:generalenquiries@education4change.org.uk" style={{color: 'white'}}>generalenquiries@education4change.org.uk</a> for more information
                         
                         </div>

                         <div></div> 

                        <Link to="/register?package=A" className="btn-sign-up">Register now</Link>

                         
                      </div>

                      

                     : offerPackage === 'B' ?
                      <div className="card-body text-left">
                        <ul className="package-list ml-3 mb-3">
                          <li>All of package A.</li>
                          <li>Access to the British Film Institute resources with schemes of work and teacher guidance.</li>
                          <li>
                            Access to an E4C facilitator to lead structured school discussions on the
                            issue of racism, identifying strengths and areas for development as part of
                            the school&#39;s self-evaluation.
                          </li>
                        </ul>
                        <Link to="/register?package=B" className="btn-sign-up">Register now</Link>
                      </div>
                      : 
                      <div className="card-body text-left">
                        <ul className="package-list ml-3 mb-3">
                          <li>All of Package A and B.</li>
                          <li>
                            Face-to-face, socially distanced training and development session for School
                            Leadership Team with an E4C leader, looking particularly at the question
                            ‘What more should our school leadership be doing to effectively address the
                            equality dimension of race?’
                          </li>
                          <li>
                            A nationally recognised lead facilitator to assist with the development and
                            delivery of pupil-led conferences, addressing issues of racism.
                          </li>
                        </ul>
                        <Link to="/register?package=C" className="btn-sign-up">Register now</Link>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img src="img/services-bg1.png" alt="" className="services__bg" /> */}
          <img src="img/services-bg-1.png" className="services__cosmos" alt="" />
        </section>

    );
}

export default OfferSection;

