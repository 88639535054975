import React from 'react';

const PartnersSection = () => {
    return (
        <section className="section partners" id="partners">
          <div className="container" style={{zIndex: 3, position: 'relative'}}>
            <div className="row">
              <div className="col">
                <div className="section-header section-header--animated section-header--center section-header--medium-margin">
                  <h4>Our friends</h4>
                  <h2>Partners</h2>
                </div>

                <div className="logoes" style={{alignContent: 'center' }}>
                  <div>
                    <img src="img/partners/partners-logo-1.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-2.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-3.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-4.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-5.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-6.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-7.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-8.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners/partners-logo-9.png" alt="" />
                  </div>
                 
                  <div>
                    <img src="img/partners/partners-logo-11.png" alt="" />
                  </div>
                  {/* <div>
                    <img src="img/partners-logo-2.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners-logo-3.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners-logo-4.png" alt="" />
                  </div>
                  <div>
                    <img src="img/partners-logo-5.png" alt="" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <img src="img/subscribe-bg.png" className="contact-bg" alt="" />
        </section>
    );
}

export default PartnersSection;

