import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext'
import './Dashboard.scss'
import { Routes } from './routes/Routes'

interface Props {}
export const Dashboard = (props: RouteComponentProps<Props>) => {
  // const history = useHistory()
  const authCtx = useAuthContext();
  useEffect(() => {
    console.log(window.location);
  }, []);

  const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  return (
    <section className="section">
      <div
        className="container-fluid"
        style={{ zIndex: 2, position: 'relative' }}
      >
        <div className="row text-left">
          <nav
            id="sidebarMenu"
            className="col-md-4 col-lg-3 d-md-block sidebar collapse"
          >
            <div className="position-sticky">
              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-gray">
                <span>Categories</span>
              </h6>
              <ul className="nav flex-column">
                {authCtx.categories
                  ? authCtx.categories.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((cat) => (
                      <li className="nav-item" key={cat._id}>
                        <NavLink
                        //   exact={true}
                          activeClassName="active"
                          to={'/dashboard/category/' + cat._id}
                          className="nav-link"
                          aria-current="page"
                        >
                          {cat.name}
                        </NavLink>
                      </li>
                    ))
                  : ''}
              </ul>

              {
                // Show admin menu
                authCtx.isInRole('Admin') ? 
                <div>
                  <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-gray">
                    <span>Administration</span>
                  </h6>
                  <ul className="nav flex-column">
                      <li className="nav-item">
                        <NavLink
                          exact={true}
                          activeClassName="active"
                          to={'/dashboard/upload-document'}
                          className="nav-link"
                          aria-current="page"
                        >
                          Add new document
                        </NavLink>
                      </li>
                  </ul>

                  <ul className="nav flex-column">
                      <li className="nav-item">
                        <NavLink
                          exact={true}
                          activeClassName="active"
                          to={'/dashboard/register-user'}
                          className="nav-link"
                          aria-current="page"
                        >
                          Register new user
                        </NavLink>
                      </li>
                  </ul>
                  <ul className="nav flex-column">
                      <li className="nav-item">
                        <NavLink
                          exact={true}
                          activeClassName="active"
                          to={'/dashboard/active-users'}
                          className="nav-link"
                          aria-current="page"
                        >
                          Active users
                        </NavLink>
                      </li>
                  </ul>




                </div>
                : ''
              }

              <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-gray">
                <span>Account settings</span>
              </h6>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <NavLink
                    exact={true}
                    activeClassName="active"
                    to="/dashboard"
                    className="nav-link"
                    aria-current="page"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact={true}
                    activeClassName="active"
                    to="/dashboard/change-password"
                    className="nav-link"
                    aria-current="page"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-key"
                    >
                      <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                    Change Password
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-key"
                    >
                      <path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path>
                    </svg>
                    Subscription
                  </a>
                </li> */}
                <li className="nav-item">
                  { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                  <a className="nav-link" style={{cursor: 'pointer'}} onClick={() => logout()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-log-out"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    Log out
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div className="col-md-10 ms-sm-auto col-lg-9 px-md-4">
            <Routes />
          </div>
        </div>
      </div>
      <img src="img/subscribe-bg.png" className="contact-bg" alt="" />
    </section>
  )
}
