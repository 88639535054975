import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import './Login.scss';
import { LoginForm } from '../../components/LoginForm/LoginForm';

interface Props {}

export const Login = (props: RouteComponentProps<Props>) => {

    // const { register, handleSubmit, formState: { errors } } = useForm();

    // const validateEmail = (email) => {
    //     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return re.test(email);
    // }

    // const onSubmit = data => {
    //     if(!validateEmail(data.email)) {
    //         alert('Please enter valid email address');
    //         return;
    //     }

    //     const httpClient = new HttpClient();
    //     httpClient.post('api/user/login', { email: data.email, password: data.password }).then(res => {
    //         console.log('Data: ', res);
    //     }).catch(error => {
    //         if (error.response && error.response.data) {
    //             alert(error.response.data.error.message);
    //         }
    //     });
    // };

    return (
        <section className="section">
            <div className="container" style={{zIndex: 2, position: 'relative'}}>
                <div className="row">
                    <div className="col text-left">
                        <div className="section-header section-header--center section-header--medium-margin">
                            <h4>Login to your account</h4>
                            <h2>Login</h2>
                        </div>
                        
                        <LoginForm />

                        <p className="text-center" style={{marginTop: '3rem'}}><Link to="/forgot-password" style={{color: 'white'}}>Forgot password?</Link></p>
                        {/* <form className="form" id="login-form" onSubmit={handleSubmit(onSubmit)}>
                            <input type="email" name="email" className="form__input" placeholder="Email" {...register("email")} />
                            <input type="password" name="password" className="form__input" placeholder="Password" {...register("password")} />
                            <button type="submit" className="form__btn btn btn--uppercase btn--orange"><span>Login</span></button>
                        </form> */}
                    </div>
                </div>
            </div>
            <img src="img/subscribe-bg.png" className="contact-bg" alt="" />
        </section>
    );
}
