import { apiRequest, GenericResponse } from './Api';

export interface UploadResponse {
  key: string;
  url: string;
  mimeType: string;
}

export interface UploadInput {
  mimeType: string,
  fileContent: any,
  fileName: string,
}


export interface SubmitDocumentRequest {
  title: string | null,
  description: string | null,
  tags: string[] | null,
  category: string[] | null,
  document: UploadResponse | null,
}

export interface DeleteFileInput {
  file: any,
  convertToJpg?: boolean
}

export const upload = async (data: UploadInput) =>
  apiRequest<UploadInput, GenericResponse<UploadResponse>>(
    'post',
    'api/upload',
    data,
    headers()
  );

export const deleteFile = async (docId: string) =>
  apiRequest<UploadInput, GenericResponse<UploadResponse>>(
    'delete',
    'api/upload/' + docId
  );

export const submit = async (data: SubmitDocumentRequest) =>
  apiRequest<SubmitDocumentRequest, GenericResponse<any>>(
    'put',
    `api/category/documents`,
    data,
    headers()
  );

const headers: any = () => {
  const tokenStr = localStorage.getItem('token');
  if(!tokenStr) {
    return null;
  }
  return {"Authorization" : `${tokenStr}`}
}