import React from 'react';
import { Link } from 'react-router-dom';

const MainMenu = (props) => {

    const goToSection = (section) => {
      console.log(section, window.location.href);
      setTimeout(() => {
        try {
          document.getElementById(section).scrollIntoView({behavior: 'smooth'});
        } catch(e) {}
      }, 150);
    }

    return (
        <ul className={props.mobile ? "mob-menu" : "menu"}>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <Link onClick={() => goToSection('about')} to="/" className={props.mobile ? "mob-menu__link" : "menu__link"}>About<span className="tablet-hidden"> Education4Change</span></Link>
            </li>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <Link onClick={() => goToSection('vision-values')} to="/" className={props.mobile ? "mob-menu__link" : "menu__link"}>Visions & Values</Link>
            </li>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <Link onClick={() => goToSection('meet-team')} to="/" className={props.mobile ? "mob-menu__link" : "menu__link"}>Meet the <span className="tablet-hidden">E4C </span>Team</Link>
            </li>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <Link onClick={() => goToSection('offer')} to="/" className={props.mobile ? "mob-menu__link" : "menu__link"}><span className="tablet-hidden">E4C </span>Offer</Link>
            </li>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <Link onClick={() => goToSection('ambassadors-network-meetings')} to="/" className={props.mobile ? "mob-menu__link" : "menu__link"}><span className="tablet-hidden">E4C </span>Ambassadors and Network Meetings</Link>
            </li>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <Link onClick={() => goToSection('partners')} to="/" className={props.mobile ? "mob-menu__link" : "menu__link"}>Partners</Link>
            </li>
            <li className={props.mobile ? "mob-menu__item" : "menu__item"}>
              <a className={props.mobile ? "mob-menu__link" : "menu__link"} href="mailto:generalenquiries@education4change.org.uk">Contact</a>
            </li>
        </ul>
    );
}

export default MainMenu;
