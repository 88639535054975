import React from 'react';

//styles
import './Page404.scss';

export const Page404 = () => {
  return (
    <div className="Page404">
      <h1>
        Nothing found <b>404</b>!
      </h1>
    </div>
  );
};
